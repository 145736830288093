<template>
  <div
    id="inspectionTerms"
    class="container"
    v-title
    :data-title="$t('i18n.inspectionTerms')"
  >
    <div id="outer-title">{{ $t("i18n.inspectionTerms") }}</div>
    <jl-form :columns="columns" :option="btnOption"></jl-form>
    <jl-table
      :tableData="auditItem"
      :columns="tableColumns"
      :options="options"
      :operates="operates"
      :height="$defined.HEIGHT - 360 + 'px'"
    ></jl-table>
    <div class="operation">
      <div class="text-right">
        <el-button
          v-if="configData.length == 0"
          type="add"
          @click="addConfig()"
          icon="el-icon-plus"
          round
        >
          {{ $t("i18n.new") }}
        </el-button>
      </div>
      <el-table :data="configData" border :max-height="500" style="width: 100%">
        <el-table-column align="center" :label="$t('i18n.operationNode')">
          <template #default="scope">
            <el-select
              class="reddot"
              :disabled="!scope.row.isAdd"
              v-model="scope.row.configFirst"
              :placeholder="$t('i18n.select')"
              @change="getMinOrMax(scope.row)"
            >
              <el-option
                v-for="(item, index) in auditItem"
                :key="item.id"
                :label="index + 1"
                :value="item.id"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column align="center" :label="$t('i18n.operationNode')">
          <template #default="scope">
            <el-select
              class="reddot"
              :disabled="!scope.row.isAdd"
              v-model="scope.row.configSecond"
              :placeholder="$t('i18n.select')"
              @change="getMinOrMax(scope.row)"
            >
              <el-option
                v-for="(item, index) in auditItem"
                :key="item.id"
                :label="index + 1"
                :value="item.id"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column align="center" :label="$t('i18n.timespan')">
          <template #default="scope">
            <el-input
              class="reddot"
              :placeHolder="$t('i18n.minutes')"
              v-if="scope.row.isAdd"
              v-model.number="scope.row.recordTime"
            ></el-input>
            <span v-else>{{ scope.row.recordTime }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" :label="$t('i18n.update')">
          <template #default="scope">
            <span v-if="scope.row.isAdd">-</span>
            <el-button v-else type="primary" @click="editFunc(scope.$index)">
              {{ $t("i18n.update") }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column align="center" :label="$t('i18n.delete')">
          <template #default="scope">
            <el-button
              v-if="scope.row.isAdd"
              type="primary"
              @click="addConfigData(scope.row, scope.$index)"
            >
              {{ $t("i18n.confirm") }}
            </el-button>
            <el-button
              v-else
              class="el-button--reset"
              @click="delConfigData(scope.row.id)"
            >
              {{ $t("i18n.delete") }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column align="center" :label="$t('i18n.new')">
          <template #default="scope">
            <el-button
              v-if="scope.row.isAdd"
              @click="cancelFunc(scope.$index, scope.row)"
              class="el-button--cancel"
            >
              {{ $t("i18n.cancel") }}
            </el-button>
            <el-button
              v-else
              class="el-button--add"
              @click="addConfig(scope.$index)"
            >
              {{ $t("i18n.new") }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import jlForm from "../../components/form";
import jlTable from "../../components/table";
export default {
  name: "InspectionBoard",
  components: {
    jlForm,
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const state = reactive({
      columns: [
        // 需要展示的列
        {
          prop: "elevatorType",
          label: "elevatorType",
          type: "select",
          data: [],
          uploadUrl: "elevator_type",
          value: "0",
          props: { label: "code", value: "value" },
          change: (val) => {
            getValue(val, "elevatorType");
          },
        },
        {
          prop: "maintenanceType",
          label: "orderType",
          type: "select",
          data: [],
          value: "0",
          props: { label: "code", value: "value" },
          uploadUrl: "billModel",
          change: (val) => {
            getValue(val, "maintenanceType");
          },
        },
        {
          prop: "postionCompare",
          label: "locationContrast",
          type: "select",
          data: [
            {
              locale: "no",
              id: "0",
            },
            {
              locale: "yes",
              id: "1",
            },
          ],
          props: { label: "locale", value: "id" },
          change: (val) => {
            getValue(val, "postionCompare");
          },
        },
        {
          prop: "ratedTime",
          label: "workingHours",
          type: "input",
          change: (val) => {
            getValue(val, "ratedTime");
          },
        },
      ],
      btnOption: [
        {
          show: false,
        },
      ],
      form: {
        maintenanceType: "0",
        elevatorType: "0",
      },
      auditItem: [],
      configData: [],
      copyData: [],
      tableColumns: [
        {
          prop: "index",
          label: "number",
          align: "center",
          width: 80,
        },
        {
          prop: "itemName",
          label: "itemContent",
          align: "left",
        },
      ],
      maintenanceItemIds: [],
      options: {
        // table 的参数
        loading: false, // 是否添加表格loading加载动画
        highlightCurrentRow: true, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: false, // 是否翻页
      },
      operates: {
        // 列操作按钮
        width: 200,
        fixed: "right",
        list: [],
      },
    });

    const getValue = (val, key) => {
      state.form[key] = val;
      if (key == "maintenanceType" || key == "elevatorType") {
        init();
      }
    };

    const init = () => {
      let form = state.form;
      if (form.maintenanceType !== "" && form.elevatorType !== "") {
        getAuditItem(form);
        getConfigData(form);
      }
    };

    const getAuditItem = async (rows) => {
      let { data } = await proxy.$api.maintenance.getAuditItem({
        elevatorType: rows.elevatorType,
        maintenanceType: rows.maintenanceType,
      });
      state.maintenanceItemIds = [];
      data.map((item, index) => {
        item.index = index + 1;
        state.maintenanceItemIds.push(item.id);
      });
      state.auditItem = state.copyData = JSON.parse(JSON.stringify(data));
    };

    const getMinOrMax = (item) => {
      item.min = state.maintenanceItemIds.indexOf(item.configFirst);
      item.max = state.maintenanceItemIds.indexOf(item.configSecond);
    };

    const getConfigData = async (rows) => {
      let { data } = await proxy.$api.maintenance.getWorkBillInspectConfig({
        elevatorType: rows.elevatorType,
        maintenanceType: rows.maintenanceType,
      });
      state.configData = state.copyData = JSON.parse(JSON.stringify(data));
      if (data.length > 0) {
        state.form.postionCompare = data[0].postionCompare + "";
        state.form.ratedTime = data[0].ratedTime;
        state.columns.map((item) => {
          item.isUpdate = true;
          if (item.prop === "postionCompare") {
            item.value = data[0].postionCompare + "";
          } else if (item.prop === "ratedTime") {
            item.value = data[0].ratedTime;
          }
        });
      }
    };

    const editFunc = (index) => {
      // 更新
      state.configData[index].isAdd = true;
    };

    const addConfigData = async (item) => {
      //新增编辑数据
      for (let key in state.form) {
        item[key] = state.form[key];
      }
      // 维保项次记录 id逗号拼接
      item.maintenanceItemId = state.maintenanceItemIds.join(",");
      if (paramsValidate(item)) {
        if (item.min >= item.max) {
          proxy.$defined.tip(t("i18n.errorSelectNode"));
          return;
        }
        let func = item.id
          ? "editWorkBillInspectConfig"
          : "addWorkBillInspectConfig";
        await proxy.$api.maintenance[func](item);
        let tip = item.id ? "edit" : "new";
        proxy.$defined.tip(t("i18n." + tip) + t("i18n.success"), "success");
        getConfigData(state.form);
        if (!item.id) {
          delete item.isAdd;
        }
      }
    };

    const delConfigData = (id) => {
      let callback = () => {
        proxy.$api.maintenance.delWorkBillInspectConfig(id).then(() => {
          getConfigData(state.form);
          proxy.$defined.tip(t("i18n.delete") + t("i18n.success"), "success");
        });
      };
      proxy.$defined.confirm(
        callback,
        t("i18n.doYouWantToIgnore"),
        t("i18n.delete")
      );
    };

    const paramsValidate = (params) => {
      let flag = false;
      for (var key in params) {
        if (params[key]) {
          return true;
        }
      }
      return flag;
    };

    const cancelFunc = (index, item) => {
      //取消
      if (item.id) {
        for (let key in state.copyData[index]) {
          state.configData[index][key] = state.copyData[index][key];
          state.configData[index].isAdd = false;
        }
      } else {
        state.configData.splice(index, 1);
      }
    };

    const addConfig = () => {
      var obj = {};
      obj.isAdd = true;
      obj.configFirst = ""; // 第一节点
      obj.configSecond = ""; // 第二节点
      obj.recordTime = ""; // 时长
      state.configData.splice(state.configData.length, 0, obj);
    };

    init();

    return {
      ...toRefs(state),
      getValue,
      editFunc,
      addConfigData,
      delConfigData,
      cancelFunc,
      addConfig,
      getMinOrMax,
    };
  },
};
</script>

<style lang="scss">
#inspectionTerms {
  .jlForm,
  .jlTable,
  .operation {
    width: 70%;
  }
  .operation {
    margin-top: 20px;
    .el-select {
      display: block;
      .el-input.is-disabled {
        .el-input__inner {
          cursor: default;
          background: transparent;
          border: none;
          text-align: center;
        }
        .el-input__suffix {
          display: none;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  #inspectionTerms {
    .jlForm,
    .jlTable,
    .operation {
      width: 100%;
    }
    button {
      min-width: unset !important;
    }
  }
}
</style>
